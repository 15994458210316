"use client";
import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

const LocationDropdown = ({ locations }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    locations[0] || "Select location"
  );

  return (
    <div className=" rounded-full lg:block bg-gray-100 relative">
      <div
        className="rounded-full border flex  text-sm lg:text-base items-center justify-between px-4 lg:py-2 py-2 w-40 lg:w-48 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedLocation}
        <ChevronDown className="w-4 h-4" />
      </div>
      {isOpen && (
        <ul className="absolute  top-full left-0 w-40 lg:w-48 mt-1 rounded-md border bg-white shadow-lg">
          {locations.map((location, index) => (
            <li
              key={index}
              className="p-2 hover:bg-gray-100 text-sm lg:text-base cursor-pointer"
              onClick={() => {
                setSelectedLocation(location);
                setIsOpen(false);
              }}
            >
              {location}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationDropdown;
