"use client";
import React from "react";
import Link from "next/link";
import {
  Search,
  Menu,
  Bell,
  Heart,
  User,
  Home,
  AlertCircle,
} from "lucide-react";
import LocationDropdown from "./location";
import { Button } from "./ui/button";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";

const Header = ({ user, locations = ["Gulu", "Kampala", "Jinja"] }) => {
  const categories = [
    { name: "All", icon: "🔍" },
    { name: "Fast Food", icon: "🍔" },
    { name: "Electronics", icon: "📱" },
    { name: "Fashion", icon: "👕" },
    { name: "Home", icon: "🏠" },
    { name: "Food", icon: "🍽️" },
    { name: "Services", icon: "🛠️" },
    { name: "Retail", icon: "🛍️" },
    { name: "Restaurants", icon: "🍴" },
    { name: "Transport", icon: "🚗" },
    { name: "Health", icon: "⚕️" },
  ];

  return (
    <header className="sticky top-0 z-50 w-full lg:shadow border-b bg-white">
      <div className="mx-auto max-w-screen-xl">
        <div className="flex items-center justify-between px-4 py-3 max-w-screen-2xl mx-auto">
          {/* Logo */}
          <Link
            href="/"
            className="text-2xl flex items-center gap-4 font-bold text-blue-600 hover:text-blue-700 transition-colors"
          >
            <img src="/logo.svg" alt="PriceTrack" className="w-8 h-8" />
            <h2>Baiaid</h2>
          </Link>

          {/* Search & Location */}
          <div className="hidden md:flex flex-1 max-w-xl mx-6">
            <div className="relative flex p-1 w-full border rounded-full items-center hover:border-blue-300 transition-colors">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Search for prices..."
                  className="w-full px-4 py-2 pl-10 text-sm focus:outline-none rounded-full"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              </div>
              <LocationDropdown locations={locations} />
            </div>
          </div>

          {/* Actions & Auth */}
          <div className="flex items-center gap-3 lg:gap-6">
            <div className="flex items-center gap-1 lg:gap-4">
              <Button
                variant="ghost"
                size="icon"
                className="relative hover:bg-gray-100"
                aria-label="Notifications"
              >
                <Bell className="w-5 h-5 lg:w-7 lg:h-7" />
                {/* Notification badge can be added here */}
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="relative hover:bg-gray-100"
                aria-label="Wishlist"
              >
                <Heart className="w-5 h-5 lg:w-7 lg:h-7" />
                {/* Wishlist count badge can be added here */}
              </Button>
            </div>
            {!user && (
              <div className="md:hidden">
                <Link href="/signup">
                  <Button
                    variant="outline"
                    size="icon"
                    className="rounded-full hover:bg-gray-100"
                  >
                    <User className="w-5 h-5" />
                  </Button>
                </Link>
              </div>
            )}

            {user ? (
              <div className="flex items-center gap-3">
                <img
                  src={user.image}
                  alt={user.name}
                  className="w-9 h-9 rounded-full border-2 border-gray-200"
                />
                <span className="hidden md:inline font-medium">
                  {user.name}
                </span>
              </div>
            ) : (
              <Link href="/signup">
                <Button
                  variant="outline"
                  className="hidden rounded-full md:inline-flex hover:bg-gray-50"
                >
                  <User className="w-5 h-5 mr-2" />
                  Sign In
                </Button>
              </Link>
            )}
          </div>
        </div>

        {/* Mobile Search */}
        <div className="md:hidden px-2 pb-2">
          <div className="relative flex p-0.5 w-full border rounded-full items-center hover:border-blue-300 transition-colors">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search for prices..."
                className="w-full px-3 pr-1 py-0.5 text-xs pl-8 focus:outline-none rounded-full"
              />
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            </div>
            <LocationDropdown locations={locations} />
          </div>
        </div>

        {/* Product Filters */}
        <div className="overflow-x-auto">
          <div className="flex items-center gap-14 py-2 max-w-screen-2xl mx-auto">
            <div className="flex items-center gap-12">
              <div className="flex gap-4 overflow-x-auto">
                {categories.map((category) => (
                  <Button
                    key={category.name}
                    variant="ghost"
                    className="lg:text-sm text-xs py-1 px-3 rounded-full hover:bg-gray-100 whitespace-nowrap"
                  >
                    <span className="mr-2">{category.icon}</span>
                    {category.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Bottom Navigation */}
        <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t z-50">
          <div className="grid grid-cols-5 gap-1 p-2">
            <Link href="/" className="flex flex-col items-center p-2">
              <Home className="w-6 h-6 text-gray-600" />
              <span className="text-xs mt-1">Home</span>
            </Link>
            <Link href="/search" className="flex flex-col items-center p-2">
              <Search className="w-6 h-6 text-gray-600" />
              <span className="text-xs mt-1">Search</span>
            </Link>
            <Link href="/alerts" className="flex flex-col items-center p-2">
              <AlertCircle className="w-6 h-6 text-gray-600" />
              <span className="text-xs mt-1">Alerts</span>
            </Link>
            <Link href="/watchlist" className="flex flex-col items-center p-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <span className="text-xs mt-1">Watchlist</span>
            </Link>
            <Sheet>
              <SheetTrigger asChild>
                <button className="flex flex-col items-center p-2 w-full">
                  <Menu className="w-6 h-6 text-gray-600" />
                  <span className="text-xs mt-1">Menu</span>
                </button>
              </SheetTrigger>
              <SheetContent
                side="left"
                className="p-0 w-80 border-r bg-gray-50/95 backdrop-blur-sm"
              ></SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
